import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { MDXRenderer } from "gatsby-plugin-mdx"
import LeftArrow from '@material-ui/icons/KeyboardBackspace';
import RightArrow from '@material-ui/icons/ArrowRightAlt';
import {handleLocation, isValidLocation, getCurrentLocation} from '../utils/checkValidLocation';
import {LinearProgress} from '@material-ui/core'
import Shutdown from '../components/Shutdown'

//import { BlogPostJsonLd } from 'gatsby-plugin-next-seo';
const removeMd = require('remove-markdown');

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
root: {
  small: {
    height: 10,
    width: 10,
  }
},
linkStyle: {
  textDecoration: 'none'
}
}));

export default ({ data, pageContext, location }) => {
  const [country, setCountry] = React.useState(null)
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let currentOrigin = window?.location?.origin || 'vusiem'
    if (currentOrigin.toLowerCase().includes('vusiem')||currentOrigin.toLowerCase().includes('museum-buddy')) {
    let currentLocation = getCurrentLocation()
    if (currentLocation?.location) {
      setCountry(currentLocation.location);
      setLoading(false);
    } else {
    var requestOptions = {
      method: 'GET',
    };
    
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=3db745ec903945059d33e698d06ffd17", requestOptions)
      .then(response => response.json())
      .then(result => {handleLocation({locationCode: result?.country?.iso_code}); 
      if (result?.country?.iso_code !== 'DE') {setCountry(result?.country?.iso_code)}; 
      setLoading(false)})
      .catch(error => {console.log('error', error); handleLocation({locationCode: 'US'}); setCountry('US'); setLoading(false)});
    }} else {setCountry('US'); setLoading(false)}
  }, []);

  const classes = useStyles();
  const {previous, next} = pageContext

  const post = data.mdx
  const postkeywords = post.frontmatter.keywords.length?post.frontmatter.keywords:['Best Musem App', "British Museum App", "Louvre App"]
  
  return (
     loading? <LinearProgress />:(
    country ? <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        featuredImage={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        keywords = {postkeywords}
        url={location}
        frontmatter={post.frontmatter}
        body={removeMd(post.rawBody)}
        type='blog'
      />
    {/*<BlogPostJsonLd
      url={location.href}
      title={post.frontmatter.title}
      images={[post.frontmatter.featuredImage.absolutePath]}
      datePublished={post.frontmatter.date}
      authorName={post.frontmatter.author}
      description={post.frontmatter.description}
      />*/}
    <Container  component="main" maxWidth="md" style={{marginTop: 70}}>
        <div style={{display:'flex', justifyContent: 'space-between', padding: 10, marginBottom: 20}}>
          {previous && (<Link to={previous.frontmatter.path} rel="prev" className={classes.linkStyle}><Button variant='contained' style={{fontSize: 10}}><LeftArrow style={{marginRight: 10}}/> Previous Post</Button></Link>)}
          {next&&(<Link to={next.frontmatter.path} rel="next" className={classes.linkStyle}><Button variant='contained' color='primary' style={{fontSize: 10}}>Next Post <RightArrow style={{marginLeft: 10}}/></Button></Link>)}
        </div>
        <Typography variant="h4" color='primary' align="center" component='h1' paragraph>{post.frontmatter.title}</Typography>
        <Divider/>

        <Typography className={classes.root} variant='body2'>
          <MDXRenderer>{post.body}</MDXRenderer>
        </Typography>
        <br/>
        <Typography variant="caption" style={{fontStyle:'italic'}} paragraph>
          Last edited on : {post.frontmatter.date}
        </Typography>
    </Container>
    </Layout>: <Shutdown />)
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      rawBody
      excerpt(pruneLength: 160)
      frontmatter {
        path
        date (formatString: "MMMM D, YYYY")
        featuredImage {
          absolutePath
          childImageSharp {fluid(maxWidth: 800) { ...GatsbyImageSharpFluid }}}
        title
        author
        keywords

      }
    }
  }
`
